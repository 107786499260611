import { HttpClient } from "@angular/common/http"
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
  HostListener,
  TemplateRef,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  computed,
} from "@angular/core"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort, Sort } from "@angular/material/sort"
import { MatTableDataSource } from "@angular/material/table"
import { Router } from "@angular/router"
import {
  BehaviorSubject,
  Observable,
  Subject,
  catchError,
  debounceTime,
  interval,
  map,
  of,
  switchMap,
  tap,
} from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { saveAs as importedSaveAs } from "file-saver"
import * as moment from "moment"
import { OverlayRef } from "@angular/cdk/overlay"
import { TooltipService } from "src/app/shared/services/tooltip.service"
import { TemplatePortal } from "@angular/cdk/portal"
import { TooltipDirective } from "../custom-directives/tooltip.directive"
import { fromObservable } from "src/app/custom-mat-search/from-observable"
import { fromSignal } from "src/app/custom-mat-search/from-signal"
import { CommonService } from "src/app/core/services/commonservice.service"

@Component({
  selector: "app-job-results-redesign",
  templateUrl: "./job-results-redesign.component.html",
  styleUrls: ["./job-results-redesign.component.scss"],
})
export class JobResultsRedesignComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort, { static: true }) sort!: MatSort
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChildren(TooltipDirective) tooltips!: QueryList<TooltipDirective>

  isLoading: boolean = false
  allJobsLoaded: boolean = false

  displayedColumns: any[] = [
    "created_on",
    "created_by",
    "sample_set_name",
    "program",
    "status",
  ]

  // jobs = new BehaviorSubject(<any>[]);
  jobs: any[] = []
  searchSubject = new Subject<string>()

  searchInput = ""
  sortState = signal(["createdDate", true])
  private readonly queryS = signal("")
  private readonly loadingN = signal(true)

  searchedWord: any
  input: any
  today = new Date()
  autoRefresh: any
  loading = new BehaviorSubject(<boolean>true)
  page = 0
  pageSize = 50
  lastPage = false
  totalSizePage: any
  id: any
  pending = false
  updateSubscription: any
  jobsLimit = 10
  jobsSkip = 0
  totalJobs: any
  listJobs: any
  searchedValue: any
  url = `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}`
  downLoadStartValue: any = null
  startDownloading: boolean = false
  initialLoadedJobs: any
  isSearched: boolean = false
  isSorted: boolean = false
  orderReverse: boolean = true
  isNextPage: boolean = false
  DownLoadFile: any
  sortStateReverse: any
  sortStateValue: any

  private overlayRef: OverlayRef | null = null
  @ViewChild("tooltipContent", { static: true })
  tooltipContent!: TemplateRef<any>
  showTooltip = false
  tooltipVisibility: boolean[] = []

  private readonly query = signal("")

  constructor(
    public authService: AuthService,
    public http: HttpClient,
    private router: Router,
    public tooltipService: TooltipService,
    private cdRef: ChangeDetectorRef,
    public commonService: CommonService,
  ) {
    this.isLoading = true
    this.searchSubject
      .pipe(
        debounceTime(600),
        switchMap((query) => {
          this.searchedWord = query
          // if (!query) {
          //   return of([]);
          // }
          return this.http.get(
            `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}&keyword=${query}`,
          )
        }),
      )
      .subscribe((res: any) => {
        if (res.data) {
          this.isSearched = false
          this.listJobs = res.data.slice(0, 10)
          this.totalJobs = res.total
        } else {
          this.isSearched = false
          this.resetAndSortJobs()
        }
      })
  }

  ngOnInit(): void {
    this.loading.next(true)
    this.getJobs()
    this.updateSubscription = interval(60000).subscribe((res: any) => {
      // let arr = [];
      this.tooltips.forEach((tooltip) => tooltip.hide())
      this.getJobs(this.url)
    })
  }

  ngAfterViewInit() {
    if (this.paginator) {
      this.listJobs.paginator = this.paginator
    }
  }

  getFirstErrorElement(settings: any[]): any {
    return settings.find((el) => el.attributes[0].name === "ERROR")
  }

  updateListJobs() {
    const start = this.paginator.pageIndex * this.paginator.pageSize
    const end = start + this.paginator.pageSize
    this.listJobs = this.jobs.slice(start, end)
  }

  public changeQuery(query: any): void {
    this.isSearched = true
    this.jobsSkip = 0
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.searchSubject.next(query)
    this.searchedValue = query
    if (!query || query.length === 0) {
      this.sortStateValue = null
      this.sortStateReverse = null
      this.totalJobs = this.initialLoadedJobs
      this.url = `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}`
    } else {
      this.url = `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}&keyword=${query}`
    }
  }

  private readonly results = fromObservable(
    fromSignal(this.query).pipe(switchMap((query) => this.filterJobs(query))),
    [],
  )

  filterJobs(query: string): any[] {
    return this.jobs.filter(
      (job: any) =>
        job.programName.toLowerCase().includes(query.toLowerCase()) ||
        job.email.toLowerCase().includes(query.toLowerCase()) ||
        job.jobStatus.toLowerCase().includes(query.toLowerCase()),
    )
  }

  resetAndSortJobs() {
    this.listJobs = [...this.jobs]
  }
  announceSortChange(sortState: Sort) {
    this.sortStateReverse = sortState.direction
    this.sortStateValue = sortState.active
    this.isSorted = true
    this.orderReverse = !this.orderReverse
    let orderReverseValue = this.orderReverse ? "true" : "false"
    let baseURL = `/list_jobs/?limit=10&skip=${this.jobsSkip}&orderField=created_date&orderReverse=${orderReverseValue}`
    if (this.searchedWord !== undefined) {
      baseURL += `&keyword=${this.searchedWord}`
    }
    this.http.get(baseURL).subscribe(
      (res: any) => {
        this.isSorted = false
        this.listJobs = res["data"]
      },
      (error) => {
        this.isSorted = false
        console.error("Error occurred:", error)
      },
    )
    setTimeout(() => {
      this.isSorted = false
    }, 5000)

    this.url = baseURL
  }

  getDate(job: any) {
    let utcDate = new Date(job.createdDate)
    let currDate = new Date(utcDate.toString() + " UTC")
    let date = currDate.toLocaleDateString()
    return date
  }

  getTime(job: any) {
    let utcDate = new Date(job.createdDate)
    let currDate = new Date(utcDate.toString() + " UTC")
    let hoursMin = currDate.toLocaleTimeString()
    return hoursMin
  }

  getGroups(data: any) {
    const groups = data["data"].reduce((groups: any, job: any) => {
      const date = this.getDate(job)
      const time = this.getTime(job)
      const name = this.getName(job)

      if (!groups[date]) {
        groups[date] = []
      }
      job.date = date
      job.time = time
      job.sample_set_name = name
      const regex = /(?<="desc":")[^"]*(?=", "job_id")/
      const match = job.settings[0].attributes[0].content.match(regex)
      const secondMatch = job.settings[1]?.attributes[0].content.match(regex)

      // capture error status
      if (job.settings?.length > 0) {
        if (job.settings[0].attributes.length > 0) {
          if (job.settings[0].attributes[0].content.includes("status")) {
            job.error_status = match
              ? match[0]
              : job.settings[0].attributes[0].content
          } else {
            job.error_status = secondMatch
              ? secondMatch[0]
              : job.settings[1]?.attributes[0].content
          }
        }
      }

      groups[date].push(job)
      return groups
    }, {})

    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        jobs: groups[date].sort(function (a: any, b: any) {
          return <any>new Date(b.createdDate) - <any>new Date(a.createdDate)
        }),
      }
    })

    return groupArrays.sort(function (a: any, b: any) {
      return new Date(a.date).getTime() - new Date(b.date).getTime()
    })
  }
  getName(job: any) {
    if (job.settings.length == 0) {
      return job.sample_set_id
    }
    let name = job.settings[0].attributes.filter((attr: any) => {
      return attr.name == "Sample Set"
    })
    // for backward compatibility when older jobs did not have any settings
    if (job.sample_set_name == null && job.settings.length) {
      if (name[0] != undefined) {
        return name[0].content
      } else {
        return job.sample_set_id
      }
    }

    if (job.sample_set_name == null) {
      return job.sample_set_id
    }
    return job.sample_set_name
  }
  getJobs(url?: any) {
    if (this.searchedWord !== undefined) {
      this.http
        .get(
          url
            ? url
            : `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}&keyword=${this.searchedWord}`,
        )
        .pipe(
          map((response: any) => {
            this.totalJobs = response.total
            this.initialLoadedJobs = response.total
            if (response.data) {
              this.allJobsLoaded = true
            }
            this.jobs = response.data.slice(0, 10)
            this.listJobs = [...this.jobs]
            if (response["data"]?.length < this.pageSize) {
              this.lastPage = true
            }
            this.totalSizePage = response.total
            if (this.id) {
              response = response.filter((job: any) => {
                return job.id === this.id
              })
            }
            let groups

            this.loading.next(false)

            this.isLoading = true
            return groups
          }),
        )
        .subscribe((res: any) => {
          this.isNextPage = false
          setTimeout(() => {
            this.pending = false
          }, 500)
        })
    } else {
      this.http
        .get(
          url
            ? url
            : `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}`,
        )
        .pipe(
          map((response: any) => {
            this.totalJobs = response.total
            this.initialLoadedJobs = response.total
            if (response.data) {
              this.allJobsLoaded = true
            }
            this.jobs = response.data.slice(0, 10)
            this.listJobs = [...this.jobs]
            if (response["data"]?.length < this.pageSize) {
              this.lastPage = true
            }
            this.totalSizePage = response.total
            if (this.initialLoadedJobs < 1) {
              this.router.navigateByUrl("/no-job")
            } else {
            }
            if (this.id) {
              response = response.filter((job: any) => {
                return job.id === this.id
              })
            }
            let groups

            this.loading.next(false)

            this.isLoading = true
            return groups
          }),
        )
        .subscribe((res: any) => {
          this.isNextPage = false
          setTimeout(() => {
            this.pending = false
          }, 500)
        })
    }
  }
  resultDate: any
  incrementTimeBy30Minutes(inputDate: Date) {
    this.resultDate = new Date(inputDate)
    this.resultDate.setMinutes(this.resultDate.getMinutes() + 30)
    this.startCountdown()
    return this.resultDate
  }
  timeLeft: any
  interval: any
  startCountdown() {
    this.timeLeft = this.resultDate
    this.interval = setInterval(() => {
      this.timeLeft -= 30
    }, 30000)
  }

  pageChanged(event: any) {
    this.isNextPage = true
    this.downLoadStartValue = null
    this.startDownloading = false
    this.jobsSkip = event.pageIndex * event.pageSize
    this.jobsLimit = event.pageSize

    const baseUrl = `/list_jobs/?skip=${this.jobsSkip}&limit=${this.jobsLimit}`
    let additionalParams = ""

    if (this.sortStateReverse && this.sortStateValue) {
      const orderReverse = this.sortStateReverse === "desc" ? "true" : "false"
      additionalParams += `&orderField=created_date&orderReverse=${orderReverse}`
    }

    if (this.searchedWord && this.searchedWord.length > 0) {
      additionalParams += `&keyword=${this.searchedWord}`
    }

    this.url = `${baseUrl}${additionalParams}`
    this.getJobs(this.url)
  }

  downloadFile(job_id?: any) {
    this.startDownloading = true
    this.downLoadStartValue = job_id
    try {
      this.DownLoadFile = this.http
        .get(`/fetch_job_result/${job_id}/`, {
          observe: "response",
          responseType: "blob",
        })
        .subscribe((res: any) => {
          if (res) {
            this.downLoadStartValue = null
            this.startDownloading = false
          }
          let filename = res.headers
            .get("content-disposition")
            .split(";")[1]
            .split("=")[1]
            .replace(/\"/g, "")
          const blob = new Blob([res.body], { type: "text/csv" })
          importedSaveAs(blob, `${filename}`)
        })
    } catch (exc) {
      console.log("Download error:", exc)
    }
  }

  getLocaleTimeOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("hh:mm A")
    return local
  }

  getLocaleDateOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("MM/DD/YYYY")
    return local
  }

  getLocaleTimeOfDayRounded(utcDateTime: any) {
    let localTime = moment.utc(utcDateTime).local()
    if (localTime.seconds() > 0) {
      localTime.add(1, "minutes")
    }
    localTime.seconds(0)
    return localTime.format(`hh:mm A`)
  }

  @HostListener("document:click")
  closeTooltip() {
    if (this.overlayRef) {
      this.overlayRef.dispose()
      this.overlayRef = null
    }
  }

  cacelDownload() {
    this.downLoadStartValue = null
    this.startDownloading = false
    this.DownLoadFile.unsubscribe()
  }

  tooltipInfo() {
    const dataToShare = this.jobs // Whatever data you want to share
    this.tooltipService.setData(dataToShare)
  }
  @ViewChild("templatePortalContent") templatePortal!: TemplatePortal<any>

  portal: TemplatePortal<any> | null = null

  togglePortal() {
    if (this.portal) {
      this.portal = null
    } else {
      this.portal = this.templatePortal
    }
  }

  timeDifference(time1: any, time2: any) {
    const date1 = new Date(time1)
    let date2 = new Date(time2)

    let differenceMs = date2.getTime() - date1.getTime()

    if (differenceMs < 0) {
      date2 = new Date(date1.getTime() + 30 * 60 * 1000)
      differenceMs = date2.getTime() - date1.getTime()
    }

    const minutes = Math.ceil(differenceMs / (1000 * 60))

    return minutes
  }

  ngOnDestroy(): void {
    this.updateSubscription.unsubscribe()
  }
}
