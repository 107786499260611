
export const environment = {
   production: true,
   baseUrl: 'https://app.matterworks.ai/api',
   cognito: {
    userPoolId: "",
    userPoolWebClientId: "",
    region: "us-east-1",
  identityPoolId: "",
  },
  auth_domain: "app.matterworks.ai",
  auth_client_id: "2bKyX3yz8B0ElqOSPfQNGBnIjYYvF1q5",
  auth_audience: "matterworks.ai",
  auth_issuer: "https://matterworks.us.auth0.com/",
  ddbTableName: 'LoginTrail',
  timeout: "3600",
  cognito_idp_endpoint: "",
  cognito_identity_endpoint:"",
  sts_endpoint: "",
  dynamodb_endpoint:"",
  s3_endpoint: "",
  users_guide: "https://matterworks.gitbook.io/pyxis-targeted-metabolomics-application/HP5L1INVgeKGRRKglGqU/nova-software-platform/running-pyxis-for-the-first-time",
  version: "3.6.0-main-cd90ff2dac2c3c90d4d790865fb8cf96875dcd3d",
  revision_date: "September 11, 2024",
  gitbook_privatekey: "undefined"
};
