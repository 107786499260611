import { HttpClient } from "@angular/common/http"
import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  signal,
} from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatPaginator } from "@angular/material/paginator"
import {
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
  MatSnackBar,
} from "@angular/material/snack-bar"
import { MatSort, Sort } from "@angular/material/sort"
import { Subject, debounceTime, switchMap, of, map, Subscription } from "rxjs"
import { AuthService } from "src/app/core/services/auth.service"
import { CommonService } from "src/app/core/services/commonservice.service"
import { AddNewUserDialogComponent } from "../../../components/add-new-user-dialog/add-new-user-dialog.component"
import { EditUserDialogComponent } from "../../../components/edit-user-dialog/edit-user-dialog.component"
import { EditRecoverUserDialogComponent } from "../../../components/edit-recover-user-dialog/edit-recover-user-dialog.component"
import { LocalStorageService } from "ngx-localstorage"

@Component({
  selector: "app-accounts",
  templateUrl: "./accounts.component.html",
  styleUrls: ["./accounts.component.scss"],
})
export class AccountsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, { static: false }) sort!: MatSort
  @ViewChild(MatPaginator) paginator!: MatPaginator
  private readonly query = signal("")
  searchInput = ""

  sortState = signal(["email", true])

  private readonly loading = signal(true)

  isLoading = false
  organization: any
  searchedValue: any

  isTotalLoad = false
  isAllLoad = false
  isAllUserLoad = false
  allUsers: any[] = []
  searchSubject = new Subject<string>()

  displayedColumns: any[] = [
    "email",
    "role",
    "organization",
    "lastSession",
    "status",
    "edit",
  ]

  listUsers: any
  usersLimit = 10
  totalUser: any
  usersSkip = 0
  initialLoadedUsers: any
  totalCountUsers: any
  userEditInfo: any
  userId: any
  userData: any
  editHeight = "550px"
  addHeight = "570px"
  userSubscription!: Subscription

  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"

  role: any
  searchedWord: any

  url = `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}`

  users: any
  lastPage = false
  pageSize = 50
  totalSizePage: any
  isSearched: boolean = false
  isSorted: boolean = false
  sortStateReverse: any
  sortStateValue: any
  currentOrg: any

  constructor(
    public http: HttpClient,
    public dialog: MatDialog,
    public authService: AuthService,
    public snackbar: MatSnackBar,
    public commonService: CommonService,
    private localStorageService: LocalStorageService,
  ) {
    this.currentOrg = this.localStorageService.get("clickedRowId") as string
    this.userData = this.authService.userdata
    this.role = this.authService.role

    this.searchSubject
      .pipe(
        debounceTime(600),
        switchMap((query) => {
          this.isSearched = true
          this.searchedWord = query
          if (!query) {
            return of([])
          }
          return this.http.get(
            `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}&keyword=${query}&group=${this.currentOrg}`,
          )
        }),
      )
      .subscribe((res: any) => {
        this.isSearched = false
        if (res.data) {
          this.listUsers = res.data.slice(0, 10)
          this.totalUser = res.total
        } else {
          this.totalUser = this.initialLoadedUsers
        }
      })
  }

  ngOnInit() {
    this.commonService.noUsers = false
    this.getUsers()
    // this.getSingleUserInfo();
    if (this.authService.role == "2") {
      this.http.get("/list_groups/").subscribe((res: any) => {
        this.commonService.groups = res
      })
    }
    if (this.authService.role == "1") {
      this.editHeight = "470px"
      this.addHeight = "470px"
    }
    this.userSubscription = this.commonService.userUpdated$.subscribe(() => {
      this.getUsers()
    })
  }
  ngAfterViewInit() {
    if (this.paginator) {
      this.listUsers.paginator = this.paginator
    }
  }

  announceSortChange(sortState: Sort) {
    this.sortStateReverse = sortState.direction
    this.sortStateValue = sortState.active
    this.isSorted = true

    const url = this.constructUrl(
      sortState.direction,
      this.usersSkip,
      this.searchedWord,
    )

    this.fetchAndAssignData(url)
    this.url = url
  }

  /**
   * Construct the URL based on the given conditions.
   *
   * @param direction Sorting direction: asc or desc
   * @param skip Number of users to skip
   * @param keyword Optional keyword for searching
   */
  private constructUrl(
    direction: string,
    skip: number,
    keyword?: string,
  ): string {
    const orderReverse = (direction === "desc").toString()
    let base = `/list_users/?limit=10&skip=${skip}&orderField=email&orderReverse=${orderReverse}&group=${this.currentOrg}`

    if (keyword !== undefined) {
      base += `&keyword=${keyword}`
    }

    return base
  }
  /**
   * Fetch data from the provided URL and assign it to class properties.
   *
   * @param url URL to fetch data from
   */
  private fetchAndAssignData(url: string): void {
    this.http.get(url).subscribe((res: any) => {
      if (res.data) {
        this.isSorted = false
      }
      this.listUsers = res["data"]
    })
  }

  getUsers(url?: any) {
    if (this.searchedWord !== undefined) {
      this.http
        .get(
          url
            ? url
            : `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}&keyword=${this.searchedWord}&group=${this.currentOrg}`,
        )
        .pipe(
          map((response: any) => {
            this.users = response.data.slice(0, 10)
            this.listUsers = [...this.users]
            if (response["data"]?.length < this.pageSize) {
              this.lastPage = true
            }
            this.totalSizePage = response.total
            if (response.data?.length) {
              this.isAllUserLoad = true
            } else {
              this.isAllUserLoad = true
              this.commonService.noUsers = true
            }
            this.initialLoadedUsers = response.total
            this.totalUser = response.total
            this.totalCountUsers = response.total

            let groups
            if (response.data?.length) {
              this.isAllLoad = true
              this.isSearched = false
            }

            this.isLoading = true
            return groups
          }),
        )
        .subscribe((res: any) => {
          if (res) {
          }
        })
    } else {
      this.http
        .get(
          url
            ? url
            : `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}&group=${this.currentOrg}`,
        )
        .pipe(
          map((response: any) => {
            this.users = response.data.slice(0, 10)
            this.listUsers = [...this.users]
            if (response["data"]?.length < this.pageSize) {
              this.lastPage = true
            }
            if (response.data?.length) {
              this.isAllUserLoad = true
            } else {
              this.isAllUserLoad = true
              this.commonService.noUsers = true
            }
            this.initialLoadedUsers = response.total
            this.totalUser = response.total
            this.totalCountUsers = response.total
            let groups
            if (response.data?.length) {
              this.isAllLoad = true
              this.isSearched = false
            }
            this.isLoading = true
            return groups
          }),
        )
        .subscribe((res: any) => {
          if (res) {
          }
        })
    }
  }

  getSingleUserInfo() {
    this.http.get("/get_user/").subscribe((res: any) => {
      this.organization = res.groups
      this.authService.firstName.set(res.given_name)
      this.authService.lastName.set(res.family_name)
      if (res.role == "2") {
        this.http.get("/list_groups/").subscribe((res: any) => {
          this.commonService.groups = res
        })
      }
      if (res.Username) {
        this.isTotalLoad = true
      }
      if (res.groups?.length > 0) {
        this.commonService.groups = res.groups
        // this.organization = ((res['cognito:groups']).toString());
      } else {
        const regexPattern = /(.+?)-/
        this.organization = res["bucket"]
        const matchResult = this.organization.match(regexPattern)
        this.organization = [matchResult ? matchResult[1] : null]
        this.commonService.organization = this.organization
      }
    })
  }

  public changeQuery(query: any) {
    this.usersSkip = 0
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.searchSubject.next(query)
    this.searchedValue = query
    if (!query || query.length === 0) {
      this.sortStateValue = null
      this.sortStateReverse = null
      this.totalUser = this.initialLoadedUsers
      this.searchedWord = ""
      this.url = `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}&group=${this.currentOrg}`
      this.isSearched = true
      this.getUsers()
    } else {
      this.url = `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}&keyword=${query}&group=${this.currentOrg}`
      this.isSearched = true
    }
  }

  openAddNewUser() {
    const dialogRef = this.dialog.open(AddNewUserDialogComponent, {
      width: "450px",
      height: this.addHeight,
      hasBackdrop: true,
      panelClass: "add-new-user",
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }

  openEditUserDialog(
    email: string,
    role: string,
    id: string,
    givenName: string,
    middleName: string,
    familyName: string,
    bucket: string,
  ) {
    const dialogRef = this.dialog.open(EditUserDialogComponent, {
      width: "450px",
      height: this.editHeight,
      hasBackdrop: true,
      panelClass: "how-to-upload",
      data: {
        email: email,
        role: role,
        id: id,
        givenName: givenName,
        middleName: middleName,
        familyName: familyName,
        bucket: bucket,
      },
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }
  openReactivetedUserDialog(
    email: string,
    role: string,
    id: string,
    givenName: string,
    middleName: string,
    familyName: string,
    bucket: string,
  ) {
    const dialogRef = this.dialog.open(EditRecoverUserDialogComponent, {
      width: "450px",
      height: this.editHeight,
      hasBackdrop: true,
      panelClass: "how-to-upload",
      data: {
        email: email,
        role: role,
        id: id,
        givenName: givenName,
        middleName: middleName,
        familyName: familyName,
      },
    })
    dialogRef.afterClosed().subscribe((res: any) => {})
  }
  pageChanged(event: any) {
    this.usersSkip = event.pageIndex * event.pageSize
    this.usersLimit = event.pageSize
    this.isSearched = true

    const baseUrl = `/list_users/?skip=${this.usersSkip}&limit=${this.usersLimit}&group=${this.currentOrg}`
    let additionalParams = ""

    if (this.sortStateReverse && this.sortStateValue) {
      const orderReverse = this.sortStateReverse === "desc" ? "true" : "false"
      additionalParams += `&orderField=email&orderReverse=${orderReverse}`
    }

    if (this.searchedWord && this.searchedWord.length > 0) {
      additionalParams += `&keyword=${this.searchedWord}`
    }

    this.url = `${baseUrl}${additionalParams}`
    this.getUsers(this.url)
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe()
    this.commonService.noUsers = false
  }
}
