import {
  Component,
  ViewChild,
  OnDestroy,
  ViewContainerRef,
  OnInit,
  signal,
  computed,
} from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { MatDrawerMode } from "@angular/material/sidenav"
import { MatSort, Sort } from "@angular/material/sort"
import { WorkerserviceService } from "../../../../core/services/workerservice.service"
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  catchError,
  debounceTime,
  map,
  of,
  switchMap,
  tap,
} from "rxjs"
import { Router } from "@angular/router"
import { HttpClient } from "@angular/common/http"
import * as moment from "moment"
import { AuthService } from "src/app/core/services/auth.service"
import { TryAgainComponent } from "../../pages/try-again/try-again.component"
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarRef,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar"
import { CustomSnackbarComponent } from "../custom-snackbar/custom-snackbar.component"
import { ImportCsvComponent } from "../import-csv/import-csv.component"
import { HowToUploadComponent } from "../how-to-upload/how-to-upload.component"
import { CommonService } from "src/app/core/services/commonservice.service"
import { EditMyProfileComponent } from "../../pages/edit-my-profile/edit-my-profile.component"
import { TermsComponent } from "../../pages/terms/terms.component"
import { CookieService } from "ngx-cookie-service"
import { saveAs as importedSaveAs } from "file-saver"
import { MatPaginator } from "@angular/material/paginator"
import { MetadataComponent } from "../../pages/metadata/metadata.component"
import { LocalStorageService } from "ngx-localstorage"

var uniqid = require("uniqid")

@Component({
  selector: "app-csv-parser",
  templateUrl: "./csv-parser.component.html",
  styleUrls: ["./csv-parser.component.scss"],
})
export class CsvParserComponent implements OnDestroy, OnInit {
  @ViewChild(MatSort, { static: true }) sort!: MatSort

  displayedColumns: any[] = [
    "set_name",
    "created_date",
    "samples",
    "created",
    "jobresults",
  ]
  role: any
  header = false
  selectedRow: any
  notifier = new Subject()
  pending = false
  lastPage = false
  scrollToTop = false
  samplesLimit = 10
  samplesSkip = 0
  totalSamples: any
  url = `/list_sets/?skip=${this.samplesSkip}&limit=${this.samplesLimit}`
  searchedWord: any
  initialLoadedSamples: any
  allSamplesSelected: any[] = []
  isSearched: boolean = false
  noQuery: boolean = false
  isSorted: boolean = false
  orderReverse: boolean = true
  nextPageLoading: boolean = false
  allSets: any
  private updateTableSubscription!: Subscription

  _sidenav: any
  element = new BehaviorSubject(<string>"")
  newSampleSet
  horizontalPosition: MatSnackBarHorizontalPosition = "end"
  verticalPosition: MatSnackBarVerticalPosition = "top"
  viewContainerRef?: ViewContainerRef

  showFirstImport: boolean = false

  @ViewChild("fileImportInput") fileImportInput: any
  @ViewChild("sidenav") set sidenav(content: any) {
    if (content) {
      this._sidenav = content
    }
  }
  @ViewChild("viewport") viewport: any
  @ViewChild(MatPaginator) paginator!: MatPaginator

  tryAgainTimeOut: any
  selectedRowIndex = -1
  isLoading: boolean = false
  snackbarRef: MatSnackBarRef<any> | undefined
  highlight(row: any) {
    this.selectedRowIndex = row.id
    this.localStorageService.set("importedCSV", row.setName)
  }

  firstPage = 0
  isPending = false
  pageSize = 50
  init = true
  count: any
  allSamples: any
  searchedValue: any
  currentBucket: any

  public searchQuery: string = ""
  currentPage = signal(this.firstPage)
  sortState = signal(["createdDate", true])
  searchSubject = new Subject<string>()
  sortStateReverse: any
  sortStateValue: any

  private readonly loading = signal(true)
  private readonly query = signal("")

  showImage = false

  isClicked = false

  constructor(
    public workerservice: WorkerserviceService,
    public commonService: CommonService,
    public dialog: MatDialog,
    private router: Router,
    public http: HttpClient,
    public snackbar: MatSnackBar,
    private cookieService: CookieService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
  ) {
    this.newSampleSet = this.router.getCurrentNavigation()?.extras
    this.commonService.query = this.query
    // if (!this.cookieService.check(this.authService.username)) {
    //   this.cookieLogin();
    // }
    this.commonService.showFirstImport = false
    this.commonService.setCurrentStep(0)
    this.searchSubject
      .pipe(
        debounceTime(600),
        switchMap((query) => {
          this.searchedWord = query
          if (!query) {
            return of([])
          }

          return this.http.get(
            `/list_sets/?skip=${this.samplesSkip}&limit=${this.samplesLimit}&keyword=${query}`,
          )
        }),
      )
      .subscribe((res: any) => {
        this.isSearched = false
        if (res.data) {
          this.allSets = res.data.slice(0, 10)
          this.totalSamples = res.total
        } else {
          this.resetAndSortUsers()
        }
      })
  }

  ngOnInit() {
    this.getSamples()
    this.updateTableSubscription =
      this.commonService.csvParserUpdated$.subscribe(() => {
        this.getSamples()
      })
    this.commonService.openSidenav.subscribe((res) => {
      if (res) {
        this.openSidenav(res)
        this.query.set(`${this.count}`)
        this.count++
      }
    })
    this.commonService.updateTable.subscribe((res) => {
      if (res) {
        this.count = uniqid()
        this.query.set(`${this.count}`)
      }
    })
    this.role = this.authService.role
    if (this.role == 0) {
      this.displayedColumns = [
        "set_name",
        "created_date",
        "samples",
        "jobresults",
      ]
    }

    if (this.allSets) {
      if (this.allSets.length > 0) {
        this.tryAgainTimeOut = setTimeout(() => {
          // this.router.navigateByUrl('/try-again')
          const dialogRef = this.dialog.open(TryAgainComponent, {
            width: "360px",
            height: "auto",
            hasBackdrop: true,
          })
          this.notifier.next(true)
          dialogRef.afterClosed().subscribe((response) => {
            if (response) {
              this.scrollToTop = true
              if (this.allSets.length > 0) {
                setTimeout(() => {
                  this.notifier.next(true)
                  const dialogRef = this.dialog.open(TryAgainComponent, {
                    width: "360px",
                    height: "auto",
                    hasBackdrop: true,
                  })
                }, 60 * 1000)
              }
            }
          })
        }, 60 * 1000)
      }
    }
  }

  public changeQuery(query: any): void {
    this.isSearched = true
    this.samplesSkip = 0
    if (this.paginator) {
      this.paginator.pageIndex = 0
    }
    this.searchSubject.next(query)
    this.searchedValue = query
    if (!query || query.length === 0) {
      this.sortStateValue = null
      this.sortStateReverse = null
      this.isSearched = false
      this.noQuery = true
      this.totalSamples = this.initialLoadedSamples
      this.url = `/list_sets/?skip=${this.samplesSkip}&limit=${this.samplesLimit}`
      setTimeout(() => {
        this.nextPageLoading = false
        this.getSamples()
      }, 1000)
    } else {
      this.url = `/list_sets/?skip=${this.samplesSkip}&limit=${this.samplesLimit}&keyword=${query}`
    }
  }

  pageChanged(event: any) {
    this.nextPageLoading = true
    this.samplesSkip = event.pageIndex * event.pageSize
    this.samplesLimit = event.pageSize

    const baseUrl = `/list_sets/?skip=${this.samplesSkip}&limit=${this.samplesLimit}`
    let additionalParams = ""

    if (this.sortStateReverse && this.sortStateValue) {
      const orderReverse = this.sortStateReverse === "desc" ? "true" : "false"
      additionalParams += `&orderField=${this.sortStateValue}&orderReverse=${orderReverse}`
    }

    if (this.searchedWord && this.searchedWord.length > 0) {
      additionalParams += `&keyword=${this.searchedWord}`
    }

    this.url = `${baseUrl}${additionalParams}`
    this.getSamples(this.url)
  }

  resetAndSortUsers() {
    this.allSets = [...this.allSamplesSelected]
  }

  getSamples(url?: any) {
    this.http
      .get(
        url
          ? url
          : `/list_sets/?skip=${this.samplesSkip}&limit=${this.samplesLimit}`,
      )
      .pipe(
        map((response: any) => {
          this.commonService.importedSamples = response.data
          this.allSets = response.data
          this.totalSamples = response.total
          this.initialLoadedSamples = response.total
          this.allSamples = response.data
          this.allSets = [...this.allSamples].slice(0, 10)
          if (response["data"]?.length < this.pageSize) {
            this.lastPage = true
          }
          if (response["data"].length < 1) {
            this.commonService.showFirstImport = true
          } else {
          }
          let groups
          // if (groups.length == 0) {
          //   console.log('no job data', this.jobs)
          //   this.router.navigateByUrl('/no-job');
          // }
          this.isLoading = true
          return groups
        }),
      )
      .subscribe((res: any) => {
        this.nextPageLoading = false
        setTimeout(() => {
          this.pending = false
        }, 500)
      })
  }

  fileChangeListener($event: any): void {
    this.header =
      (this.header as unknown as string) === "true" || this.header === true
  }

  closeSidenav() {
    console.log("tatas")

    this._sidenav.close()
    this.commonService.sidenavClosed.next(true)
  }

  openSidenav(element: any) {
    this.currentBucket = this.localStorageService.set(
      "currentBucket",
      element.bucket,
    )
    if (element.status !== "CONFIGURED") {
      this.searchQuery = ""
      this.commonService.elementSetName = element.setName
      this.commonService.elementId = element.id
      this.commonService.isTableClicked = true
      const dialogRef = this.dialog.open(MetadataComponent, {
        hasBackdrop: false,
        panelClass: "metadataDialog",
      })

      dialogRef.afterOpened().subscribe(() => {
        setTimeout(() => {
          if (
            dialogRef.componentInstance &&
            dialogRef.componentInstance.stepper
          ) {
            dialogRef.componentInstance.stepper.selectedIndex = 1
          }
        })
      })

      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.router.navigate(["/metadata"])
        }
      })
    } else {
      if (!this._sidenav?.opened) {
        this.commonService.isSidenavOpen = true
      } else {
        this.commonService.isSidenavOpen = false
      }

      this._sidenav?.toggle()
      this.commonService.sidenavClosed.next(true)
      this.selectedRow = element
      let samplesetId = element.id
      if (this._sidenav?.opened) {
        this.element.next(element)
      }
    }
  }

  mode = new FormControl("over" as MatDrawerMode)

  importCsv() {
    const dialogRef = this.dialog.open(ImportCsvComponent, {
      // const dialogRef = this.dialog.open(TextImportDialogComponent, {
      width: "640px",
      height: "326px",
      hasBackdrop: true,
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.success(res)
        this.router.navigate(["/metadata"])
      }
    })
  }

  howToUpload() {
    const dialogRef = this.dialog.open(HowToUploadComponent, {
      width: "761px",
      height: "629px",
      hasBackdrop: true,
    })
  }

  termsDialog() {
    const dialogRef = this.dialog.open(TermsComponent, {
      width: "450px",
      height: "256px",
      hasBackdrop: true,
    })
  }

  extract_job_from_s3_path(s3_path: any) {
    let s3_path_split = s3_path.split("/")
    let jobid = s3_path_split[s3_path_split.length - 2]
    return jobid
  }

  downloadFile(s3_path: any) {
    try {
      let job_id = this.extract_job_from_s3_path(s3_path)
      console.log(
        "Downloading results from:",
        job_id,
        " extracted from:",
        s3_path,
      )
      this.http
        .get(`/fetch_job_result/${job_id}/`, {
          observe: "response",
          responseType: "blob",
        })
        .subscribe((res: any) => {
          let filename = res.headers
            .get("content-disposition")
            .split(";")[1]
            .split("=")[1]
            .replace(/\"/g, "")
          const blob = new Blob([res.body], { type: "text/csv" })
          importedSaveAs(blob, `${filename}`)
        })
    } catch (exc) {
      console.error("Download error:", exc)
    }
  }

  editProfile() {
    const dialogRef = this.dialog.open(EditMyProfileComponent, {
      // const dialogRef = this.dialog.open(TextImportDialogComponent, {
      width: "450px",
      height: "550px",
      hasBackdrop: true,
      // data: { file: result}
    })
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        // this.success(res)
        this.router.navigate(["/metadata"])
      }
    })
  }

  announceSortChange(sortState: Sort) {
    this.sortStateReverse = sortState.direction
    this.sortStateValue = sortState.active

    this.isSorted = true
    this.orderReverse = !this.orderReverse
    let orderReverseValue = this.orderReverse ? "true" : "false"
    const validFields = ["created_date", "set_name"]
    if (validFields.includes(sortState.active)) {
      const baseQuery = `/list_sets/?limit=10&skip=${this.samplesSkip}&orderField=${sortState.active}&orderReverse=${orderReverseValue}`

      let query = baseQuery
      if (this.searchedWord !== undefined) {
        query += `&keyword=${this.searchedWord}`
      }
      this.http.get(query).subscribe((res: any) => {
        this.allSets = res["data"]
        this.isSorted = false
      })
      this.url = query
      setTimeout(() => {
        this.isSorted = false
      }, 5000)
    }
  }

  getLocaleTimeOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("hh:mm A")
    return local
  }

  getLocaleDateOfDay(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("MM/DD/YYYY")
    return local
  }

  getLocaleDateOfAll(utcDateTime: any) {
    let local = moment.utc(utcDateTime).local().format("MM/DD/YYYY hh:mm A")
    return local
  }

  success(resp: any) {
    this.http
      .post(`configure_sample_set/`, { setId: resp[0].id })
      .pipe(
        catchError((err: any) => {
          throw "error in source. Details: " + err
        }),
      )
      .subscribe((response: any) => {
        if (response.setName) {
          response.set_name = response.setName
          delete response.setName
        }
        // if (response.createdDate) {
        //   response.created_date = response.createdDate
        //   delete response.createdDate
        // }
        this.allSets.unshift(response)

        this.commonService.showFirstImport = false
        let name = response.set_name
        let successfullyText = "was imported successfully"
        let sampleIdlink = response.samples[0].id
        let snackBarRef = this.snackbar.openFromComponent(
          CustomSnackbarComponent,
          {
            data: {
              name: name,
              successfullyText: successfullyText,
              linkSample: sampleIdlink,
            },
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: [
              "my-custom-snackbar-error",
              "mat-toolbar",
              "mat-primary",
            ],
            duration: 10000,
          },
        )
        snackBarRef.afterDismissed().subscribe((res) => {
          if (res.dismissedByAction) {
            this.openSidenav(response)
          }
        })
      })
  }

  ngOnDestroy(): void {
    window.clearTimeout(this.tryAgainTimeOut)
    this.notifier.next("")
    this.notifier.complete()
    this.updateTableSubscription.unsubscribe()
  }
}
