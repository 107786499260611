<section class="container-add">
  <article class="content-add-user">
    <div class="closing-btn">
      <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div *ngIf="!commonService.userLoading">
      <div class="title-add-user">Add New User Profile</div>
      <div>
        <form
          [formGroup]="addNewUserForm"
          (ngSubmit)="addNewUser()"
          class="form-add-new-user"
        >
          <mat-form-field appearance="outline">
            <mat-label>Email address</mat-label>
            <input matInput type="email" formControlName="email" required />
            <mat-error
              *ngIf="addNewUserForm.controls['email'].hasError('email')"
              >Email not valid</mat-error
            >
            <mat-error
              *ngIf="addNewUserForm.controls['email'].hasError('required')"
              >Email is required</mat-error
            >
            <mat-error
              *ngIf="
                !addNewUserForm.controls['email'].hasError('email') &&
                addNewUserForm.controls['email'].hasError('maxlength')
              "
            >
              Maximum length exceeded (320 characters).
            </mat-error>
          </mat-form-field>

          <div class="container-row-only gap8">
            <mat-form-field appearance="outline">
              <mat-label>First name</mat-label>
              <input
                matInput
                type="text"
                formControlName="firstName"
                required
              />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>Middle name</mat-label>
              <input matInput type="text" formControlName="middleName" />
            </mat-form-field>
          </div>

          <div class="container-row-only gap8">
            <mat-form-field appearance="outline">
              <mat-label>Last name</mat-label>
              <input matInput type="text" formControlName="lastName" required />
            </mat-form-field>

            <mat-form-field appearance="outline">
              <mat-label>Select a group</mat-label>
              <mat-select
                [formControl]="selectCtrl"
                [disabled]="selectCtrl.disabled"
                (closed)="clearSearch()"
              >
                <mat-option
                  *ngFor="let option of filteredOptions"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div [ngClass]="this.role !== '1' ? 'display-block' : 'display-none'">
            <mat-form-field appearance="outline" class="w100">
              <mat-label>Select a role</mat-label>
              <mat-select formControlName="role">
                <mat-option *ngFor="let role of rolesList" [value]="role">
                  <div [ngSwitch]="role">
                    <span *ngSwitchCase="'0'"> User </span>
                    <span *ngSwitchCase="'1'"> Admin </span>
                    <span *ngSwitchCase="'2'"> Super Admin </span>
                  </div>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="button-wrapper gap15 mt10px">
            <button mat-flat-button [mat-dialog-close]="false">Cancel</button>
            <button
              mat-flat-button
              color="warn"
              class="text-white"
              type="submit"
              [disabled]="!addNewUserForm.valid || commonService.clicked"
            >
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="commonService.userLoading">
      <div class="container-spinner">
        <div class="spinner">
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </article>
</section>
