<app-current-organization></app-current-organization>
<section class="accounts-container">
  <section class="container-title">
    <div class="search-add">
      <mat-form-field class="input-new" appearance="outline">
        <mat-icon matPrefix class="icon-grey">search</mat-icon>
        <input
          matInput
          placeholder="Search"
          [(ngModel)]="searchInput"
          (input)="changeQuery(searchInput)"
        />
      </mat-form-field>
      <button
        mat-flat-button
        matPrefix
        type="button"
        color="accent"
        class="text-white apply-program-btn import-btn"
        (click)="openAddNewUser()"
      >
        Add New User
      </button>
    </div>
    <section class="container-column h-parser main-section">
      <div class="container">
        <div class="csv-result-table job-results">
          <table
            mat-table
            [dataSource]="listUsers"
            *ngIf="isAllUserLoad"
            matSort
            class="mat-elevation-z8 csv-parser-content table-users"
            (matSortChange)="announceSortChange($event)"
            matSortDisableClear
          >
            <ng-container matColumnDef="email">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by email"
                start="desc"
              >
                <div class="first-head">Account</div>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="element.enabled ? '' : 'opacity'"
                class="first-col"
              >
                <span class="bold"
                  >{{ element.firstName }} {{ element.lastName }}</span
                ><br />
                <span class="text-normal">{{ element.email }}</span>
              </td>
            </ng-container>
            <ng-container matColumnDef="username">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                start="desc"
              >
                Username
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="element.enabled ? '' : 'opacity'"
              >
                <!-- {{element.createdDate}} -->
                {{ element.username }}
              </td>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef>Role</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="element.enabled ? '' : 'opacity'"
              >
                <ng-container [ngSwitch]="element.role">
                  <div *ngSwitchCase="0">User</div>
                  <div *ngSwitchCase="1">Admin</div>

                  <div *ngSwitchCase="2">Super Admin</div>
                </ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="organization">
              <th mat-header-cell *matHeaderCellDef>
                <span *ngIf="this.role == '2'"> User groups </span>
              </th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="element.enabled ? '' : 'opacity'"
              >
                <div *ngIf="this.role == '2'">
                  <div *ngFor="let group of element.groups">
                    {{ group.name }}
                  </div>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="lastSession">
              <th mat-header-cell *matHeaderCellDef>Last Accessed</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="element.enabled ? '' : 'opacity'"
              >
                <div *ngIf="element.lastAccessed">
                  {{ element.lastAccessed | date: "short" }}
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td
                mat-cell
                *matCellDef="let element"
                [ngClass]="element.enabled ? '' : 'opacity'"
              >
                <div *ngIf="element.enabled == true" class="status-info">
                  <img src="../../../../../assets/icons/check.svg" />
                  <span>Active</span>
                </div>
                <div *ngIf="element.enabled == false" class="status-info">
                  <img src="../../../../../assets/icons/arhive.svg" />
                  <span>Archive</span>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.enabled == true">
                  <button
                    mat-button
                    (click)="
                      openEditUserDialog(
                        element.email,
                        element.role,
                        element.id,
                        element.firstName,
                        element.middleName,
                        element.lastName,
                        element.bucket
                      )
                    "
                  >
                    Edit
                  </button>
                </div>
                <div *ngIf="element.enabled == false">
                  <button
                    mat-button
                    (click)="
                      openReactivetedUserDialog(
                        element.email,
                        element.role,
                        element.id,
                        element.firstName,
                        element.middleName,
                        element.lastName,
                        element.bucket
                      )
                    "
                  >
                    Edit
                  </button>
                </div>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4">No data matching for filtered value "{{searchedValue}}"
                            </td>
                        </tr> -->
          </table>
          <table
            mat-table
            [dataSource]="listUsers"
            *ngIf="!isAllUserLoad && !commonService.noUsers"
            matSort
            class="mat-elevation-z8 csv-parser-content table-users"
            (matSortChange)="announceSortChange($event)"
            matSortDisableClear
          >
            <ng-container matColumnDef="email">
              <th
                mat-header-cell
                *matHeaderCellDef
                mat-sort-header
                sortActionDescription="Sort by email"
                class="headers"
                start="desc"
              >
                <div class="first-head">Account</div>
              </th>
            </ng-container>
            <ng-container matColumnDef="role">
              <th mat-header-cell *matHeaderCellDef>Role</th>
            </ng-container>
            <ng-container matColumnDef="organization">
              <th mat-header-cell *matHeaderCellDef>
                <span *ngIf="this.role == '2'"> User groups </span>
              </th>
            </ng-container>
            <ng-container matColumnDef="lastSession">
              <th mat-header-cell *matHeaderCellDef>Last Accessed</th>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
            </ng-container>
            <ng-container matColumnDef="edit">
              <th mat-header-cell *matHeaderCellDef></th>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!-- <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data matching for filtered value "{{searchedValue}}"
                </td>
            </tr> -->
          </table>
          <h2 *ngIf="commonService.noUsers" class="noUsers">
            There are no users for this account!
          </h2>
          <mat-paginator
            *ngIf="isAllUserLoad"
            class="paginator"
            [length]="totalUser"
            [pageSize]="usersLimit"
            [pageSizeOptions]="[10]"
            (page)="pageChanged($event)"
          ></mat-paginator>
        </div>
      </div>
    </section>
  </section>
</section>

<div *ngIf="!isAllUserLoad || isSearched || isSorted">
  <div class="container-spinner">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>
</div>
